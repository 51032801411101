import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormRangeSlider from "../CommonDashboardComponents/FormRangeSlider";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import SimpleReactValidator from "simple-react-validator";
import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import {
  GET_OTHER_INVESTMENTS,
  IS_DIRECT,
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_UPDATE_ASSETS_API,
  DMF_GETBANKLIST_API_URL,
  CHECK_SESSION,
  GET_MEMBER_LIST,
} from "../../../../constants";
import {
  apiCall,
  fetchEncryptData,
  fv,
  getFpUserDetailId,
  getItemLocal,
  getParentUserId,
  getUserId,
  loginRedirectGuest,
} from "../../../../common_utilities";
import axios from "axios";
import { useDispatch } from "react-redux";
import moment from "moment";

const numericRegex = new RegExp(/^\d*\.?\d*$/);

const govtSchemeInputs = {
  default: [
    "bankInstituteName",
    "bankInstituteNameOther",
    "accountBalanceToday",
    "investmentAmountPerFrequency",
    "expectedRateOfReturn",
    "maturityDate",
    "maturityAmount",
  ],

  // Pension Schemes
  "national pension scheme (nps)": [
    "bankInstituteName",
    "bankInstituteNameOther",
    "accountBalanceToday",
    "investmentAmountPerFrequency",
    "expectedRateOfReturn",
    "maturityDate",
    "maturityAmount",
  ],
  "ppf/gpf/vpf": [
    "bankInstituteName",
    "bankInstituteNameOther",
    "accountBalanceToday",
    "investmentAmountPerFrequency",
    "expectedRateOfReturn",
    "maturityDate",
    "maturityAmount",
  ],
  "nsc/kvp": [
    "schemeName",
    "investmentDate",
    "investmentAmount",
    "expectedRateOfReturn",
    "maturityDate",
    "maturityAmount",
  ],
  "sukanya samriddhi yojana": [
    "investmentDate",
    "accountBalanceToday",
    "investmentAmountPerFrequency",
    "expectedRateOfReturn",
    "maturityDate",
    "maturityAmount",
  ],
  "post office scheme": [
    "schemeName",
    "purchaseDate",
    "investedAmount",
    "expectedRateOfReturn",
    "payoutType",
    "maturityDate",
    "maturityAmount",
  ],
  others: [
    "schemeName",
    "purchaseDate",
    "purchaseAmount",
    "interestRate",
    "payoutType",
    "maturityDate",
    "maturityAmount",
  ],
};

function isInputInPolicy(inputName, policyType) {
  // if (!policyType) {
  //   lowercasePolicyType = "default";
  //   console.log(lowercasePolicyType,"&&&&")

  // }
  // else{
  //   var lowercasePolicyType = policyType.toLowerCase();
  // }

  // console.log(policyType,"6666")
  if (!policyType) policyType = "default";
  console.log("lowercasePolicyType", policyType);
  return govtSchemeInputs[policyType.toLowerCase()].includes(inputName);
  // return true
}

const initialState = {
  dateOfInvestment: "",
  typeOfAsset: "",
  govtSchemeMemberName:"",
  bankInstituteName: "",
  bankInstituteNameOther: "",
  accountBalanceToday: 0,
  investmentAmountPerFrequency: 0,
  investmentAmount: 0,
  expectedRateOfReturn: 0,
  maturityDate: "",
  maturityAmount: 0,
  schemeName: "",
  investmentDate: "",
  interestRate: 0,
  purchaseDate: "",
  investedAmount: "",
  payoutType: "Cumulative",
  asset_frequency: "1",
  asset_isPerpetual: "",
};

const NewGovtSchemesView = () => {
  const [, forceUpdate] = useState();
  const [formData, setFormData] = useState(initialState);

  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("Monthly");

  const [activeIndex2, setActiveIndex2] = useState(0);
  const [selectedValue2, setSelectedValue2] = useState("Monthly");
  const [allBank, setAllBank] = useState([]);
  const dispatch = useDispatch();
  const [period, setperiod] = useState("");
  const [rate, setrate] = useState("");
  // const [maturityamount, setMaturityCalculation] = useState('');
  const { id } = useParams();
  const [editdata, setEditData] = useState("");
  const [session, setSession] = useState("");
  const [maturityamount, setMaturityCalculation] = useState(0);
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [familyData, setFamilyData] = useState([]);
  
  const options = [
    {
      value: "National Pension Scheme (NPS)",
      label: "National Pension Scheme (NPS)",
    },
    { value: "PPF/GPF/VPF", label: "PPF/GPF/VPF" },
    { value: "NSC/KVP", label: "NSC/KVP" },
    { value: "Sukanya Samriddhi Yojana", label: "Sukanya Samriddhi Yojana" },
    { value: "Post Office Scheme", label: "Post Office Scheme" },
    { value: "Others", label: "Others" },
  ];

  const optionSubCategory = {
    "National Pension Scheme (NPS)": 118,
    "PPF/GPF/VPF": 81,
    "NSC/KVP": 84,
    "Sukanya Samriddhi Yojana": 86,
    "Post Office Scheme": 78,
    Others: 87,
  };

  const optionInstituteName = [
    {
      value: "National Pension Scheme (NPS)",
      label: "National Pension Scheme (NPS)",
    },
  ];

  const options_payout = [
    { value: "Cumulative", label: "Cumulative" },
    { value: "Non-Cumulative", label: "Non-Cumulative" },
  ];
  useEffect(() => {
    checksession();
    // console.log(activeIndex,"kkkk")
  }, []);

  const checksession = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);

      if (session_data.error_code == "100") {
        setSession(session_data);
      } else {
        loginRedirectGuest();
      }
    } catch (error) {
      console.log(error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something Went Wrong1");
    }
  };

  useEffect(() => {
    let calculatematurityamount = 0.0;
    if (
      formData.typeOfAsset === "Post Office Scheme" ||
      formData.typeOfAsset === "NSC/KVP" ||
      formData.typeOfAsset === "Others"
    ) {
      if (
        formData?.maturityDate &&
        (formData?.purchaseDate || formData?.investmentDate)
      ) {
        const mf_yr_f = moment(formData?.maturityDate)
          .format("DD/MM/YYYY")
          .split("/");
        const c_yr_f = moment(formData?.purchaseDate ? formData?.purchaseDate :formData?.investmentDate)
          .format("DD/MM/YYYY")
          .split("/");
        const firstDate = new Date(
          parseInt(mf_yr_f[2]),
          parseInt(mf_yr_f[1]) - 1,
          parseInt(mf_yr_f[0])
        );
        const secondDate = new Date(
          parseInt(c_yr_f[2]),
          parseInt(c_yr_f[1]) - 1,
          parseInt(c_yr_f[0])
        );
        const timeDiff = Math.abs(firstDate.getTime() - secondDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const nper = parseFloat((diffDays / 365.2425).toFixed(2));
        let amt = 0
        if (formData.typeOfAsset === "Post Office Scheme"){
          amt = formData.investedAmount
        }
        else if (formData.typeOfAsset === "Others"){
          amt = formData.purchaseAmount
        }
        else{
          amt = formData.investmentAmount
        }

        let assetROR = 0
        if (formData.typeOfAsset === "Others"){
          assetROR = formData.interestRate
        }
        else{
          assetROR = formData.expectedRateOfReturn
        }
      
        calculatematurityamount = fv(
          assetROR,
          nper,
          0,
          amt
        );

        if (
          formData.payoutType === "Non-Cumulative"
        ) {
          calculatematurityamount = formData.investedAmount;
        } else {
          calculatematurityamount =
            calculatematurityamount > 999999999
              ? Math.round(calculatematurityamount)
              : calculatematurityamount.toFixed(2);
        }
      }
    } else if (
      formData.typeOfAsset === "PPF/GPF/VPF" ||
      formData.typeOfAsset === "Sukanya Samriddhi Yojana" ||
      formData.typeOfAsset === "National Pension Scheme (NPS)" 
      ) {
        const assetMaturityDate = moment(formData?.maturityDate).format(
          "DD/MM/YYYY"
        );
      if (assetMaturityDate && assetMaturityDate != "Invalid date") {
        const c_yr = session?.data?.plan_date ?session["data"]["plan_date"] : new Date();
        const c_yr_date = new Date(c_yr);
        const mf_yr_f = assetMaturityDate.split("/");
        const adatetosplit = assetMaturityDate;
        const firstDate = new Date(
          parseInt(mf_yr_f[2]),
          parseInt(mf_yr_f[1]) - 1,
          parseInt(mf_yr_f[0])
        );
        const secondDate = c_yr_date;
        const timeDiff = Math.abs(firstDate.getTime() - secondDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const nper = parseFloat((diffDays / 365.2425).toFixed(2));
        const month_var = [0, 12, 4, 2, 1];

        if (formData.investmentAmountPerFrequency) {
          const pmt =
            parseInt(formData.investmentAmountPerFrequency) *
            month_var[formData.asset_frequency];
          calculatematurityamount = fv(
            formData.expectedRateOfReturn,
            nper,
            pmt,
            formData.accountBalanceToday
          );

          calculatematurityamount =
            calculatematurityamount > 999999999
              ? Math.round(calculatematurityamount)
              : calculatematurityamount.toFixed(2);
          if (parseInt(formData.purchaseAmount) == 0) {
            calculatematurityamount = 0;
          }
        }
      }
    } 

    if (formData.investedAmount == "") {
      setMaturityCalculation(Number(0))
    }

    if (
      calculatematurityamount !== 0 &&
      calculatematurityamount !== null &&
      calculatematurityamount !== undefined
    ) {
      setMaturityCalculation(Number(calculatematurityamount))
    } else {
      setMaturityCalculation(Number(0));
    }

  }, [
    formData?.investedAmount,
    formData?.purchaseDate,
    formData?.maturityDate,
    formData?.payoutType,
    formData?.expectedRateOfReturn,
    formData?.asset_frequency,
    formData?.accountBalanceToday,
    formData?.interestRate,
  ]);

  const getFamilyMembers = async () => {
    try {
      let data = {
        user_id: getParentUserId(),
        is_direct: "1",
      };

      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);

      if (member_data.error_code == "100") {
        var member_array = [];
        var members = member_data["data"];
        members.map((member) => {
          if (member.parent_user_id == 0) {
            member_array.push({ value: member.fp_user_id, label: "Self", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            setFormData({
              ...formData,
              govtSchemeMemberName: member.fp_user_id,
            });
          
            member_array.push({ value: 0, label: "Family", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            
          } else {
            member_array.push({
              value: member.fp_user_id,
              label: member.NAME + " " + member.last_name,
              retirement_age: member.retirement_age,
              dob: member.dob,
              life_expectancy: member.life_expectancy,
              isdependent: member.isdependent
            });
          }
        });

        setFamilyData(member_array);
      } else {
        setFamilyData([]);
      }
    } catch { }
  };

  useEffect(() => {
    getFamilyMembers()
    if (id != undefined) {
      fetchInsuranceData(atob(id));
    }
  }, []);

  const fetchInsuranceData = async (usrparts) => {
    try {
      let payload_data = {
        user_id: getParentUserId(),
        inv_type: "all",
        is_direct: IS_DIRECT,
      };
      let payload = {
        url: GET_OTHER_INVESTMENTS,
        data: payload_data,
        method: "post",
      };

      let res = await fetchEncryptData(payload);

      if (res.error_code == "100") {
        var data = res.data["po_data"]["po_details"];

        var pay_out = "";
        if (usrparts) {
          const assetDetails = data.find(
            (item) => item.post_id == usrparts ?? usrparts
          );
          setEditData(assetDetails);
          const pay_out =
            assetDetails.asset_payout_type == "1" &&
            assetDetails.frequency == "Monthly"
              ? "Cumulative"
              : "Non-Cumulative";
          // if (assetDetails.category_name == "NPS") {
          //   if (assetDetails.category_name === "NPS") {
          //     assetDetails.category_name = "National Pension Scheme (NPS)";
          //   }

          //   setFormData(prevFormData => ({ ...prevFormData,maturityAmount:assetDetails.maturity_amount.toString()}))
          // }
          if (assetDetails.category_name == "Post Office Scheme") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              typeOfAsset: assetDetails.category_name,
              schemeName: assetDetails.scheme_name,
              purchaseDate: assetDetails.asset_purchase_date,
              expectedRateOfReturn: assetDetails.interest,
              maturityDate: assetDetails?.date_maturity ? assetDetails.date_maturity : "",
              maturityAmount: assetDetails.maturity_amount,
              investedAmount: assetDetails.current_value,
              payoutType: pay_out,
              asset_frequency: assetDetails.asset_frequency,
              govtSchemeMemberName : assetDetails.asset_member_id
            }));
          } else if (assetDetails.category_name == "NSC/ KVP") {
            if (assetDetails.category_name === "NSC/ KVP") {
              assetDetails.category_name = assetDetails.category_name.replace(
                "/ ",
                "/"
              );
            }
            setFormData((prevFormData) => ({
              ...prevFormData,
              typeOfAsset: assetDetails.category_name,
              schemeName: assetDetails.scheme_name,
              investmentDate: assetDetails.asset_purchase_date,
              expectedRateOfReturn: assetDetails.interest,
              maturityDate: assetDetails?.date_maturity ? assetDetails.date_maturity : "",
              maturityAmount: assetDetails.maturity_amount,
              investmentAmount: assetDetails.amount,
              payoutType: pay_out,
              asset_frequency: assetDetails.asset_frequency,
              govtSchemeMemberName : assetDetails.asset_member_id
            }));
          } else if (assetDetails.category_name == "NPS") {
            if (assetDetails.category_name === "NPS") {
              assetDetails.category_name = "National Pension Scheme (NPS)";
            }

            setFormData((prevFormData) => ({
              ...prevFormData,
              typeOfAsset: assetDetails.category_name,
              maturityAmount: assetDetails.maturity_amount.toString(),
              bankInstituteName: assetDetails.scheme_name,
              investmentDate: assetDetails.asset_purchase_date,
              interestRate: assetDetails.interest,
              maturityDate: assetDetails?.date_maturity ? assetDetails.date_maturity : "",
              accountBalanceToday: assetDetails.current_value,
              investmentAmountPerFrequency: assetDetails.amount,
              payoutType: pay_out,
              asset_frequency: assetDetails.asset_frequency,
              expectedRateOfReturn: assetDetails.interest,
              govtSchemeMemberName : assetDetails.asset_member_id
            }));
          } else if (assetDetails.category_name == "PPF/ GPF/ VPF") {
            assetDetails.category_name = assetDetails.category_name.replace(
              /\s*\/\s*/g,
              "/"
            );
            setFormData((prevFormData) => ({
              ...prevFormData,
              typeOfAsset: assetDetails.category_name,
              bankInstituteName: assetDetails.scheme_name,
              investmentDate: assetDetails.asset_purchase_date,
              interestRate: assetDetails.interest,
              maturityDate: assetDetails?.date_maturity ? assetDetails.date_maturity : "",
              maturityAmount: assetDetails.maturity_amount,
              investmentAmount: assetDetails.current_value,
              payoutType: pay_out,
              asset_frequency: assetDetails.asset_frequency,
              accountBalanceToday: assetDetails.asset_current_unit_price,
              investmentAmountPerFrequency: assetDetails.amount,
              expectedRateOfReturn: assetDetails.interest,
              govtSchemeMemberName : assetDetails.asset_member_id
            }));
          } else if (assetDetails.category_name == "Sukanya Samriddhi Yojana") {
            setFormData((prevFormData) => ({
              ...prevFormData,
              typeOfAsset: assetDetails.category_name,
              maturityDate: assetDetails?.date_maturity ? assetDetails.date_maturity : "",
              accountBalanceToday: assetDetails.asset_current_unit_price,
              maturityAmount: assetDetails.maturity_amount,
              interestRate: assetDetails.interest,
              asset_frequency: assetDetails.asset_frequency,
              investmentAmountPerFrequency: assetDetails.amount,
              investmentDate: assetDetails.asset_purchase_date,
              asset_frequency: assetDetails.asset_frequency,
              govtSchemeMemberName : assetDetails.asset_member_id
            }));
          } else if (assetDetails.category_name == "Others") {
            // var paytype =''
            // if(assetDetails.frequency == 'Monthly')
            // {
            //     paytype = 'Cumulative'
            // }
            // else{
            //   paytype = 'Non-Cumulative'
            // }
            setFormData((prevFormData) => ({
              ...prevFormData,
              typeOfAsset: assetDetails.category_name,
              schemeName: assetDetails.scheme_name,
              purchaseDate: assetDetails.asset_purchase_date,
              interestRate: assetDetails.interest,
              maturityDate: assetDetails?.date_maturity ? assetDetails.date_maturity : "",
              purchaseAmount: assetDetails.amount,
              investmentAmountPerFrequency: assetDetails.amount,
              payoutType: pay_out,
              asset_frequency: assetDetails.asset_frequency,
              expectedRateOfReturn: assetDetails.interest,
              maturityAmount: assetDetails.maturity_amount,
              govtSchemeMemberName : assetDetails.asset_member_id
            }));
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
              typeOfAsset: assetDetails.category_name,
              maturityDate: assetDetails?.date_maturity ? assetDetails.date_maturity : "",
              accountBalanceToday: assetDetails.asset_current_unit_price,
              maturityAmount: assetDetails.maturity_amount,
              interestRate: assetDetails.interest,
              asset_frequency: assetDetails.asset_frequency,
              investmentAmountPerFrequency: assetDetails.amount,
              investmentDate: assetDetails.asset_purchase_date,
              asset_frequency: assetDetails.asset_frequency,
              govtSchemeMemberName : assetDetails.asset_member_id
            }));

          }
        }
      } else {
        console.error(e);
      }
    } catch (e) {
      console.error(":::>>", e);
    }
  };

  console.log(formData, "ddddd");
  useEffect(() => {
    getBanks();
  }, []);

  const reqdata = () => {
    var maturityDate = moment(formData.maturityDate).format("DD/MM/YYYY");
    var dateOfInvestment = moment(formData.dateOfInvestment).format(
      "DD/MM/YYYY"
    );
    var currentdate = moment(new Date()).format("DD/MM/YYYY");

    var payouttype = formData.payoutType == "Cumulative" ? "1" : "2";
    var asset_frequency = "";
    if(formData.typeOfAsset === "Post Office Scheme" ||
    formData.typeOfAsset === "Others"){
      if (formData.payoutType === "Cumulative") {
        asset_frequency = "1";
        payouttype = "1";
      } else {
        asset_frequency = formData.asset_frequency;
        payouttype = "2";
      }
    }
    else{
      asset_frequency = formData.asset_frequency;
    }

    let asset_sub_category_id = optionSubCategory[formData.typeOfAsset];

    let asset_name = "";

    if (formData.typeOfAsset === "Sukanya Samriddhi Yojana") {
      asset_name = formData.typeOfAsset;
    } else if (
      formData.typeOfAsset == "National Pension Scheme (NPS)" ||
      formData.typeOfAsset == "PPF/GPF/VPF"
    ) {
      asset_name = formData.bankInstituteName;
    } else if (
      formData.typeOfAsset === "Post Office Scheme" ||
      formData.typeOfAsset === "Others" ||
      formData.typeOfAsset == "NSC/KVP"
    ) {
      asset_name = formData.schemeName;
    }

    let asset_purchase_amount = "";
    if (
      formData.typeOfAsset === "National Pension Scheme (NPS)" ||
      formData.typeOfAsset === "PPF/GPF/VPF" ||
      formData.typeOfAsset === "Sukanya Samriddhi Yojana"
    ) {
      asset_purchase_amount = formData.investmentAmountPerFrequency;
    } else if (formData.typeOfAsset === "Others") {
      asset_purchase_amount = formData.purchaseAmount;
    } else if (formData.typeOfAsset === "NSC/KVP") {
      asset_purchase_amount = formData.investmentAmount;
    } else {
      asset_purchase_amount = formData.purchaseAmount;
    }

    let asset_ror = "";
    if (
      formData.typeOfAsset === "Others"
    ) {
      asset_ror = formData.interestRate;
    } else {
      asset_ror = formData.expectedRateOfReturn;
    }

    let asset_maturity_date = "";
    asset_maturity_date = formData.maturityDate;

    let asset_purchase_date = "";
    if (formData.typeOfAsset === "NSC/KVP") {
      asset_purchase_date = moment(formData.investmentDate).format(
        "DD/MM/YYYY"
      );
    } else if (formData.typeOfAsset === "Post Office Scheme") {
      asset_purchase_date = moment(formData.purchaseDate).format("DD/MM/YYYY");
      asset_purchase_amount = formData.investedAmount;
    } else if (formData.typeOfAsset === "Others") {
      asset_purchase_date = moment(formData.purchaseDate).format("DD/MM/YYYY");
    } else if (
      formData.typeOfAsset === "National Pension Scheme (NPS)" ||
      formData.typeOfAsset === "PPF/GPF/VPF" ||
      formData.typeOfAsset === "Sukanya Samriddhi Yojana"
    ) {
      asset_purchase_date = null;
    } else {
      asset_purchase_date = moment(formData.dateOfInvestment).format(
        "DD/MM/YYYY"
      );
    }

    console.log(asset_frequency, "shree111");
    var req = {
      Created_By: 0,
      Updated_By: 0,
      asset_amount: 0,
      asset_abreturn: "0",
      annual_growth_rate: "10",
      asset_broker_id: 0,
      asset_category_id: 38,
      asset_citytype: "0",
      asset_current_unit_price: formData.accountBalanceToday,
      asset_currency: false,
      asset_ecas_type: "manual",
      asset_epf_ismanual: "1",
      asset_folio_number: null,
      asset_footnote: null,
      asset_frequency: asset_frequency,
      asset_goal_link_id: 0,
      asset_goalname: null,
      asset_gold_karat: 0,
      asset_isActive: "1",
      asset_ismortgage: "0",
      asset_isperpetual: "",
      asset_isallocation: false,
      asset_iselss: "1",
      asset_islinkable: true,
      asset_isrecurring: false,
      asset_isrented: "1",
      asset_maturity_amt: maturityamount,
      asset_maturity_date: maturityDate,
      asset_member_id: formData.govtSchemeMemberName,
      asset_mf_end_date: maturityDate,
      asset_name: asset_name,
      asset_pan: null,
      asset_payout_type: payouttype,
      asset_pin_code: "",
      asset_purchase_amount: asset_purchase_amount,
      asset_purchase_date: asset_purchase_date,
      asset_rental_amount: 0,
      asset_rental_income: null,
      asset_ror: asset_ror,
      asset_sub_category_id: asset_sub_category_id,
      asset_unique_code: "",
      asset_units: 0,
      categorydetail: "Debt",
      created_datetime: currentdate,
      employee_contribution: "",
      employer_contribution: "",
      installment_ispaid: 1,
      membername1: "",
      stock_mf: null,
      stock_name: null,
      subcategorydetail: formData.typeOfAsset,
      totalinvestedvalue: "0.00",
      totalpurchasevalue: "0.00",
      totalmaturtiyamount: "",
      updated_datetime: currentdate,
      user_id: getParentUserId(),
      scheme_equityshare: {},
      isEditable: true,
      asset_source: "1",
    };
    if (id != undefined) {
      req["id"] = atob(id);
      req["linked_goals_id"] = [];
    }

    console.log(req, "req...");
    return req;
  };

  const AddGovScheme = async () => {
    try {
      var schemedata = reqdata();
      var payload = "";

      if (id != undefined) {
        payload = {
          method: "POST",
          url: ADVISORY_UPDATE_ASSETS_API,
          data: schemedata,
        };
      } else {
        payload = {
          method: "POST",
          url: ADVISORY_ADD_ASSETS_API,
          data: schemedata,
        };
      }

      var Apicall = await fetchEncryptData(payload);
      // console.log(Apicall['error_code'], "apicall<<")

      //   change condition as per API response
      if (Apicall["error_code"] == "100") {
        if (id != undefined) {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Gov Scheme Updated Successfully!",
              type: "success",
            },
          });
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Gov Scheme Added Successfully!",
              type: "success",
            },
          });
        }

        navigate(
          process.env.PUBLIC_URL +
            "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=4"
        );
      }
    } catch (e) {
      console.log(e, ">>>>>>>>>");
    }
  };

  const getBanks = async () => {
    try {
      const payload = {
        method: "POST",
        url: DMF_GETBANKLIST_API_URL,
        data: {},
      };
      const bankResp = await fetchEncryptData(payload);
      setAllBank(bankResp.data);
    } catch (e) {
      console.log(e);
    }
  };

  const onInputChange = (e, isNumeric) => {
    const name = e.target.name;
    let value = e.target.value;

    if (isNumeric && !numericRegex.test(value) && value !== "") {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    var form = simpleValidator.current.showMessages();

    const isValid = simpleValidator.current.allValid();

    if (isValid == true) {
      AddGovScheme();
    }
    forceUpdate(1);
  };

  const getGoldTypeData = (goldTypeData, label) => {
    return goldTypeData.find((data) => data.label === label);
  };

  const handleGOvtSchemeMember = (selectedOption) => {
    setFormData({
      ...formData,
      govtSchemeMemberName: selectedOption.value,
    });
  };

  const emptystates = () => {
    setFormData((prevformData) => ({
      ...prevformData,
      maturityDate: "",
      investmentAmountPerFrequency: "",
      interestRate: 0,
      accountBalanceToday: "",
      investmentDate: "",
      bankInstituteName: "",
      expectedRateOfReturn: 0,
      schemeName: "",
      purchaseDate: "",
    }));
  };

  const showSubmit = () => {
    if (formData.typeOfAsset === "EPF") {
      return showEPFForm;
    } else {
      return true;
    }
  };

  simpleValidator.current.purgeFields();
  // Select Options Styles
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "#ffff",
        color: isFocused ? "#042b62" : isSelected ? "#042b62" : "gray",
        cursor: "pointer",
      };
    },
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      scrollBehavior: "smooth",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#042b62",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  return (
    <>
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          <div className="d-flex">
            <Link
              to={
                process.env.PUBLIC_URL +
                "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=4"
              }
            >
              {" "}
              <img
                style={{
                  transform: "rotate(180deg)",
                }}
                width={20}
                height={20}
                src={process.env.PUBLIC_URL + "/static/media/icons/chevron.svg"}
              />
            </Link>

            <h3
              className="text-center pb-0 mb-0 ps-2"
              style={{
                flexGrow: 1,
              }}
            >
              {id ? "Edit Your Govt Schemes" : "Add Your Govt Schemes"}
            </h3>
          </div>
          <hr style={{ color: "#afafaf" }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              <p className="text-center">
                {id
                  ? "Enter Your Details"
                  : "Enter Your Details To Add Existing Assets"}
              </p>
              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div className="">
                    <span className="lbl-newbond">Select Type Of Asset *</span>
                    <br />
                    <Select
                      className={`fnto-dropdown-react ${id ? "disabled" : ""}`}
                      classNamePrefix=" sortSelect"
                      isSearchable={false}
                      options={options}
                      styles={customStyles}
                      name="typeOfAsset"
                      value={getGoldTypeData(options, formData.typeOfAsset)}
                      onChange={(e) => {
                        onDateAndSelectInputChange("typeOfAsset", e.label),
                          emptystates();
                      }}
                    />
                    {simpleValidator.current.message(
                      "typeOfAsset",
                      formData.typeOfAsset,
                      "required"
                    )}
                  </div>
                </div>

                <div
                  className="row"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <>
                    {isInputInPolicy(
                      "bankInstituteName",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-12 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Select Bank Institute Name *
                            </span>
                            <br />
                            <Select
                              className="fnto-dropdown-react"
                              styles={customStyles}
                              classNamePrefix=" sortSelect"
                              isSearchable={true}
                              options={allBank.map((v) => ({
                                label: v.bank_name,
                                value: v.bank_name,
                              }))}
                              value={
                                allBank
                                  .filter(
                                    (v) =>
                                      v.bank_name == formData.bankInstituteName
                                  )
                                  .map((v) => ({
                                    label: v.bank_name,
                                    value: v.bank_name,
                                  }))[0] ?? null
                              }
                              // name={selectedOption}
                              name="bankInstituteName"
                              onChange={(e) =>
                                onDateAndSelectInputChange(
                                  "bankInstituteName",
                                  e.label
                                )
                              }
                            />
                            {simpleValidator.current.message(
                              "bankInstituteName",
                              formData.bankInstituteName,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="my-md-4">
                            <div className="">
                              <span className="lbl-newbond">
                                Who Is This Investment For*
                              </span>
                              <br />
                              <Select
                                className={`fnto-dropdown-react`}
                                classNamePrefix="sortSelect"
                                isSearchable={false}
                                styles={customStyles}
                                options={familyData}
                                name="govtSchemeMemberName"
                                onChange={handleGOvtSchemeMember}
                                value={familyData.filter(
                                  (v) => v.value == formData.govtSchemeMemberName
                                )}
                              />
                              
                              {simpleValidator.current.message(
                                "govtSchemeMemberName",
                                formData.govtSchemeMemberName,
                                "required"
                              )}
                            </div>
                          </div>
                    {/* {
                      isInputInPolicy('bankInstituteNameOther', formData.typeOfAsset) && <div className="col-md-12 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Bank Institute Name
                            </span>
                            <br />
                            <input
                              placeholder="Enter Bank Institute Name"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              value={formData.bankInstituteNameOther}
                              name="bankInstituteNameOther"
                              onChange={onInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    } */}

                    {isInputInPolicy(
                      "dateOfInvestment",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Date Of Investment *
                            </span>
                            <br />

                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  formData.dateOfInvestment === ""
                                    ? ""
                                    : new Date(formData.dateOfInvestment)
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="dateOfInvestment"
                                customClass="datePickerDMF"
                                onChange={(e) =>
                                  onDateAndSelectInputChange(
                                    "dateOfInvestment",
                                    formatDatefun(e)
                                  )
                                }
                              />
                            </div>
                            {simpleValidator.current.message(
                              "dateOfInvestment",
                              formData.dateOfInvestment,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {isInputInPolicy("schemeName", formData.typeOfAsset) && (
                      <div className="col-md-12 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">Scheme Name *</span>
                            <br />
                            <input
                              placeholder="Enter Your Scheme Name Here"
                              className={` w-100 fntoo-textbox-react inputPlaceholder`}
                              type="text"
                              value={formData.schemeName}
                              name="schemeName"
                              onChange={onInputChange}
                            />
                          </div>
                          {simpleValidator.current.message(
                            "schemeName",
                            formData.schemeName,
                            "required"
                          )}
                        </div>
                      </div>
                    )}

                    {formData.typeOfAsset === "Sukanya Samriddhi Yojana"
                      ? ""
                      : isInputInPolicy(
                          "investmentDate",
                          formData.typeOfAsset
                        ) && (
                          <div className="col-md-6 col-12">
                            <div className="my-md-4">
                              <div className="">
                                <span className="lbl-newbond">
                                  Date Of Investment *
                                </span>
                                <br />
                                <div className="bonds-datepicker">
                                  <FintooDatePicker
                                    // dateFormat="yyyy/MM/dd"
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                      formData.investmentDate === ""
                                        ? ""
                                        : new Date(formData.investmentDate)
                                    }
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={new Date()}
                                    dropdownMode="select"
                                    name="investmentDate"
                                    customClass="datePickerDMF"
                                    onChange={(e) =>
                                      onDateAndSelectInputChange(
                                        "investmentDate",
                                        formatDatefun(e)
                                      )
                                    }
                                  />
                                </div>
                                {simpleValidator.current.message(
                                  "investmentDate",
                                  formData.investmentDate,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    {isInputInPolicy(
                      "accountBalanceToday",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Account Balance Today *
                            </span>
                            <br />
                            <input
                              placeholder="Enter a/c Bal on Today"
                              className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                              type="text"
                              value={formData.accountBalanceToday}
                              maxLength={10}
                              name="accountBalanceToday"
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message(
                              "accountBalanceToday",
                              formData.accountBalanceToday,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {isInputInPolicy(
                      "investmentAmountPerFrequency",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Investment Amount (Per Frequency) *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Amount"
                              className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                              type="text"
                              maxLength={10}
                              value={formData.investmentAmountPerFrequency}
                              name="investmentAmountPerFrequency"
                              // onChange={(e) => onInputChange(e, true)}
                              onChange={(e) => {
                                onInputChange(e, true);
                              }}
                            />
                            {simpleValidator.current.message(
                              "investmentAmountPerFrequency",
                              formData.investmentAmountPerFrequency,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                          <div className="fnto-bonds-tags">
                            <div
                              className={
                                formData?.asset_frequency == "1" ? "active" : ""
                              }
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  asset_frequency: "1",
                                }))
                              }
                            >
                              <p>Monthly</p>
                            </div>
                            <div
                              className={
                                formData?.asset_frequency == "2" ? "active" : ""
                              }
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  asset_frequency: "2",
                                }))
                              }
                            >
                              <p>Quarterly</p>
                            </div>
                            <div
                              className={
                                formData?.asset_frequency == "3" ? "active" : ""
                              }
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  asset_frequency: "3",
                                }))
                              }
                            >
                              <p>Semi Anually</p>
                            </div>
                            <div
                              className={
                                formData?.asset_frequency == "4" ? "active" : ""
                              }
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  asset_frequency: "4",
                                }))
                              }
                            >
                              <p>Anually</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {isInputInPolicy("purchaseDate", formData.typeOfAsset) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">Purchase Date *</span>
                            <br />
                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                maxDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  formData.purchaseDate === ""
                                    ? ""
                                    : new Date(formData.purchaseDate)
                                }
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="purchaseDate"
                                customClass="datePickerDMF"
                                onChange={(e) =>
                                  onDateAndSelectInputChange(
                                    "purchaseDate",
                                    formatDatefun(e)
                                  )
                                }
                              />
                            </div>
                            {simpleValidator.current.message(
                              "purchaseDate",
                              formData.purchaseDate,
                              "required"
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {isInputInPolicy(
                      "purchaseAmount",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Purchase Amount *
                            </span>
                            <br />
                            <div className="bonds-datepicker">
                              <input
                                placeholder="Enter Investment Amount"
                                className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                                type="text"
                                value={formData.purchaseAmount}
                                name="purchaseAmount"
                                maxLength={10}
                                onChange={onInputChange}
                              />
                            </div>
                            {simpleValidator.current.message(
                              "purchaseAmount",
                              formData.purchaseAmount,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {isInputInPolicy(
                      "investedAmount",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Invested Amount*
                            </span>
                            <br />
                            <input
                              placeholder="Enter Investment Amount"
                              className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                              type="text"
                              value={formData.investedAmount}
                              name="investedAmount"
                              maxLength={10}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message(
                              "investedAmount",
                              formData.investedAmount,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {isInputInPolicy(
                      "investmentAmount",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Investment Amount*
                            </span>
                            <br />
                            <input
                              placeholder="Enter Investment Amount"
                              className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                              type="text"
                              value={formData.investmentAmount}
                              name="investmentAmount"
                              // onChange={(e) => onInputChange(e, true)}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                if (value > 0 || e.target.value === "") {
                                  // Accepts positive values or empty string
                                  onInputChange(e, true);
                                }
                              }}
                            />
                            {simpleValidator.current.message(
                              "investmentAmount",
                              formData.investmentAmount,
                              "required|numeric|min:1,num"
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {isInputInPolicy("interestRate", formData.typeOfAsset) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">Interest Rate *</span>
                            <br />
                            <FormRangeSlider
                              x={formData.interestRate}
                              min={0}
                              max={20}
                              step={0.1}
                              onChange={(x) => {
                                onDateAndSelectInputChange(
                                  "interestRate",
                                  Math.round(x * 100) / 100
                                );
                              }}
                            />
                          </div>
                        </div>
                        {simpleValidator.current.message(
                          "interestRate",
                          formData.interestRate,
                          "required"
                        )}
                      </div>
                    )}
                    {isInputInPolicy(
                      "expectedRateOfReturn",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Expected Rate Of Return *
                            </span>
                            <p className="mb-0" style={{ height: "8px" }}>
                              &nbsp;
                            </p>
                            <FormRangeSlider
                              x={formData.expectedRateOfReturn}
                              min={0}
                              max={20}
                              step={0.1}
                              onChange={(x) => {
                                onDateAndSelectInputChange(
                                  "expectedRateOfReturn",
                                  Math.round(x * 100) / 100
                                );
                              }}
                            />
                          </div>
                        </div>
                        {/* {simpleValidator.current.message('expectedRateOfReturn', formData.expecteinterestRatedRateOfReturn, 'required')} */}
                      </div>
                    )}
                    {isInputInPolicy("payoutType", formData.typeOfAsset) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">Payout Type*</span>
                            <br />
                            <Select
                              className="fnto-dropdown-react"
                              classNamePrefix=" sortSelect"
                              isSearchable={false}
                              styles={customStyles}
                              options={options_payout}
                              name="payoutType"
                              value={getGoldTypeData(
                                options_payout,
                                formData.payoutType
                              )}
                              onChange={(e) =>
                                onDateAndSelectInputChange(
                                  "payoutType",
                                  e.label
                                )
                              }
                            />
                            {simpleValidator.current.message(
                              "payoutType",
                              formData.payoutType,
                              "required"
                            )}
                          </div>
                          {formData.payoutType === "Non-Cumulative" && (
                            <>
                              <div className="fnto-bonds-tags">
                                <div
                                  className={
                                    formData?.asset_frequency == 1
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      asset_frequency: 1,
                                    }))
                                  }
                                >
                                  <p>Monthly</p>
                                </div>
                                <div
                                  className={
                                    formData?.asset_frequency == 2
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      asset_frequency: 2,
                                    }))
                                  }
                                >
                                  <p>Quarterly</p>
                                </div>
                                <div
                                  className={
                                    formData?.asset_frequency == 3
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      asset_frequency: 3,
                                    }))
                                  }
                                >
                                  <p>Semi Anually</p>
                                </div>
                                <div
                                  className={
                                    formData?.asset_frequency == 4
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      asset_frequency: 4,
                                    }))
                                  }
                                >
                                  <p>Anually</p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    

                    {isInputInPolicy(
                          "maturityDate",
                          formData.typeOfAsset
                        ) && (
                          <div className="col-md-6 col-12">
                            <div className="my-md-4">
                              <div className="">
                                <span className="lbl-newbond">
                                  Maturity Date *
                                </span>
                                <br />
                                <div className="bonds-datepicker">
                                  <FintooDatePicker
                                    minDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                      formData.maturityDate === ""
                                        ? ""
                                        : new Date(formData.maturityDate)
                                    }
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    name="maturityDate"
                                    customClass="datePickerDMF"
                                    onChange={(e) =>
                                      onDateAndSelectInputChange(
                                        "maturityDate",
                                        formatDatefun(e)
                                      )
                                    }
                                    onKeyDown={(e) => e.preventDefault()}
                                  />
                                </div>
                                {simpleValidator.current.message(
                                  "maturityDate",
                                  formData.maturityDate,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                    {isInputInPolicy(
                      "maturityAmount",
                      formData.typeOfAsset
                    ) && (
                      <div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div className="">
                            <span className="lbl-newbond">
                              Maturity Amount *
                            </span>
                            <br />
                            <input
                              placeholder=" Maturity Amount Auto calculated"
                              className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder disabled`}
                              type="text"
                              value={maturityamount}
                              name="maturityAmount"
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {/* {simpleValidator.current.message('maturityAmount', formData.maturityAmount, 'required|numeric')} */}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>

                {showSubmit() && (
                  <div className="my-md-4">
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => validateForm()}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewGovtSchemesView;
