import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Link, useSearchParams } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import FormRangeSlider from "../CommonDashboardComponents/FormRangeSlider";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import FormSwitch from "../CommonDashboardComponents/formSwitch";
import SimpleReactValidator from "simple-react-validator";
// import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import OTPInput from "otp-input-react";
import {
  DMF_ADDFDBOND_API_URL,
  DMF_GETBANKLIST_API_URL,
  ADVISORY_ADD_ASSETS_API,
  ADVISORY_GET_ASSETS_API,
  ADVISORY_UPDATE_ASSETS_API,
  ADVISORY_CALCULATE_EPF_MATURITY_AMOUNT,
  IS_DIRECT,
  CHECK_SESSION,
  exchange_rate,
  GET_MEMBER_LIST,
} from "../../../../constants";
import * as toastr from "toastr";
import "toastr/build/toastr.css";
import {
  fetchEncryptData,
  getUserId,
  apiCall,
  getFpUserDetailsId,
  getParentUserId,
  getItemLocal,
  fv,
} from "../../../../common_utilities";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { MdHomeMax } from "react-icons/md";
import Form from "react-bootstrap/Form";
import Switch from "react-switch";
const numericRegex = new RegExp(/^\d*\.?\d*$/);

const initialState = {
  nameOfUnlistedEquity: "Unlisted / AIF Equity",
  aifmembername: "",
  purchaseDate: "",
  noofShares: "",
  avgbuyPrice: "",
  investedValue: "",
  currentPrice: "",
  currentValue: "",
};

const defaultAssetDetails = {
  Created_By: 0,
  Updated_By: 0,
  asset_amount: "",
  asset_abreturn: "0",
  annual_growth_rate: "10",
  asset_broker_id: 0,
  asset_category_id: 29,
  asset_citytype: "0",
  asset_current_unit_price: "",
  asset_currency: false,
  asset_ecas_type: "manual",
  asset_epf_ismanual: "1",
  asset_folio_number: null,
  asset_footnote: null,
  asset_frequency: "1",
  asset_goal_link_id: 0,
  asset_goalname: null,
  asset_gold_karat: 0,
  asset_isActive: "1",
  asset_ismortgage: "0",
  asset_isperpetual: "3",
  asset_isallocation: false,
  asset_iselss: "1",
  asset_islinkable: true,
  asset_isrecurring: false,
  asset_isrented: "1",
  asset_maturity_amt: 0,
  asset_maturity_date: null,
  asset_member_id: 0,
  asset_mf_end_date: null,
  asset_name: "Unlisted / AIF Equity",
  asset_pan: null,
  asset_payout_type: "1",
  asset_pin_code: "",
  asset_purchase_amount: "",
  asset_purchase_date: null,
  asset_rental_amount: "",
  asset_rental_income: null,
  asset_ror: "0",
  asset_sub_category_id: 33,
  asset_unique_code: "",
  asset_units: "",
  categorydetail: "Equity",
  created_datetime: moment().format("DD/MM/YYYY"),
  employee_contribution: "",
  employer_contribution: "",
  installment_ispaid: 1,
  membername1: "",
  stock_mf: null,
  stock_name: null,
  subcategorydetail: "",
  totalinvestedvalue: "",
  totalpurchasevalue: "",
  totalmaturtiyamount: "",
  updated_datetime: moment().format("DD/MM/YYYY"),
  user_id: 0,
  scheme_equityshare: {},
  linked_goals_id: [],
};

const NewUnlisted_Aif_EquityFormView = () => {
  const [, forceUpdate] = useState(1);
  const [formData, setFormData] = useState(initialState);
  const [assetsDetails, setAssetsDetails] = useState(defaultAssetDetails);
  const [addForm, setAddForm] = useState(true);
  const [updateForm, setUpdateForm] = useState(false);
  const [assetEditId, setAssetEditId] = useState("");

  const [activeIndex, setActiveIndex] = useState(1);
  const [sliderValue, setSliderValue] = useState("Yearly");
  const [maturityCalculation, setMaturityCalculation] = useState("");

  const [activeIndex2, setActiveIndex2] = useState(4);
  const [isInstallmentPaid, setIsInstallmentPaid] = useState(true);

  const [interestRate, setInterestRate] = useState(0);
  const [rateOfReturn, setRateOfReturn] = useState(8.1);
  const [growthRate, setGrowthRate] = useState(10);
  const [yearsOfService, setYearsOfService] = useState("5");
  const [allBank, setAllBank] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [session, setSession] = useState([]);
  const [familyData, setFamilyData] = useState([]);

  // ---------------------------------------------------- EPF states ----------------------------------------------------
  const [showEPFForm, setShowEPFForm] = useState(false);
  const [showUANModal, setShowUANModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [isManual, setIsManual] = useState(false);

  // --------------------------------------------------------------------------------------------------------------------

  const simpleValidator = useRef(new SimpleReactValidator());

  useEffect(() => {
    checksession();
    getFamilyMembers();
    // console.log(activeIndex,"kkkk")
  }, []);

  const checksession = async () => {
    try {
      let url = CHECK_SESSION;
      let data = { user_id: getParentUserId(), sky: getItemLocal("sky") };
      let session_data = await apiCall(url, data, true, false);

      if (session_data.error_code == "100") {
        setSession(session_data);
      } else {
        loginRedirectGuest();
      }

      const urlParams = new URLSearchParams(window.location.search);
      let asset_id = urlParams.get("id");
      if (asset_id) {
        setUpdateForm(true);
        setAddForm(false);
        setAssetEditId(asset_id);
        editAIFData(asset_id);
      }
    } catch (error) {
      console.log('here56767575--', error);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something Went Wrong1");
    }
  };

  const getFamilyMembers = async () => {
    try {
      let data = {
        user_id: getParentUserId(),
        is_direct: "1",
      };

      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);

      if (member_data.error_code == "100") {
        var member_array = [];
        var members = member_data["data"];
        members.map((member) => {
          if (member.parent_user_id == 0) {
            member_array.push({ value: member.fp_user_id, label: "Self", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            setFormData({
              ...formData,
              aifmembername: member.fp_user_id,
            });
          
            member_array.push({ value: 0, label: "Family", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            
          } else {
            member_array.push({
              value: member.fp_user_id,
              label: member.NAME + " " + member.last_name,
              retirement_age: member.retirement_age,
              dob: member.dob,
              life_expectancy: member.life_expectancy,
              isdependent: member.isdependent
            });
          }
        });

        setFamilyData(member_array);
      } else {
        setFamilyData([]);
      }
    } catch { }
  };
  const formatDatefun = (date) => {
    // return moment(date).format("YYYY/MM/DD");
    console.log("uuuu: ", moment(date).toDate());

    return moment(date).toDate();
  };

  const handleAIFEquityMember = (selectedOption) => {
    setFormData({
      ...formData,
      aifmembername: selectedOption.value,
    });
  };
  const editAIFData = async (id) => {
    try {
      let url = ADVISORY_GET_ASSETS_API;
      let payload = {
        id: id,
        user_id: getParentUserId(),
        asset_type: "none",
      };
      let editAIF = await apiCall(url, payload, true, false);
      if (editAIF["error_code"] == "100") {
        // var fdData = editFdData["data"][0];

        var msg = editAIF["data"][0]["subcategorydetail"]
          ? " - " + editAIF["data"][0]["subcategorydetail"]
          : "";

        var editData = editAIF["data"][0];

        let temp_form_data = {};

        temp_form_data["asset_sub_category_id"] =
          editData["asset_sub_category_id"];

          temp_form_data["subcategorydetail"] = editData["subcategorydetail"];

          temp_form_data["nameOfUnlistedEquity"] = editData["asset_name"];
          temp_form_data["purchaseDate"] =editData["asset_purchase_date"] ? moment(editData["asset_purchase_date"], "DD/MM/YYYY").toDate():"";
          temp_form_data["noofShares"] = editData["asset_units"];
          temp_form_data["avgbuyPrice"] = editData["asset_purchase_amount"]; 
          temp_form_data["currentPrice"] = editData["asset_current_unit_price"]; 
          temp_form_data["investedValue"] = editData["totalinvestedvalue"]; 
          temp_form_data["currentValue"] = editData["totalpurchasevalue"]; 
          temp_form_data["aifmembername"] = editData["asset_member_id"]; 
          temp_form_data["categorydetail"] = "Equity";
        
        setFormData({
          ...formData,
          ...temp_form_data,
        });
        
      }
    } catch (err) {
      console.log('here3435--', err);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const numericRegex = /^[0-9]*\.?[0-9]*$/;;
  const onInputChange = (e, isNumeric) => {
    const name = e.target.name;
    let value = e.target.value;
    console.log(name,value)
    if (isNumeric && !numericRegex.test(value) && value !== "") {
      return;
    }
    if (
      (name === "currentPrice" ||
        name === "avgbuyPrice" ||
        name === "noofShares") &&
      value.length > 0 &&
      value[0] === "0"
    ) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const onPurchaseInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.replace(/[^0-9]/, ""),
    });
  };

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    if (simpleValidator.current.allValid()) {
      // addFdBond();
      addAssets();
      // setFormData({ ...initialState });
      // setInterestRate(0);
      // simpleValidator.current.hideMessages();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate((v) => ++v);
    }
  };

  const updateAIF = () => {
    if (
      simpleValidator.current.allValid() ) {
      updateAIFequity();
    } else {
      simpleValidator.current.showMessages();
      forceUpdate((v) => ++v);
    }
  };

  console.log(formData, "formdata");

  const addAssets = async () => {
    try {
      let url = ADVISORY_ADD_ASSETS_API;
      let aifData = assetsDetails;
      

      aifData["user_id"] = getParentUserId();
      // let member_id = await getFpUserDetailsId();
      aifData["asset_member_id"] = formData.aifmembername;
      // console.log("Fp member:", member_id);

      aifData["subcategorydetail"] = "Unlisted / AIF Equity";

      aifData["asset_name"] = formData.nameOfUnlistedEquity;
      aifData["asset_purchase_date"] = formData.purchaseDate ? moment(formData.purchaseDate).format(
        "DD/MM/YYYY" ) : "";
      aifData["asset_units"] = formData.noofShares;
      aifData["asset_purchase_amount"] = formData.avgbuyPrice;
      aifData["asset_current_unit_price"] = formData.currentPrice;
      aifData["totalinvestedvalue"] = formData.investedValue;
      aifData["totalpurchasevalue"] = formData.currentValue;
      aifData["categorydetail"] = "Equity";
      aifData["asset_sub_category_id"] = 33;

      let addassetData = await apiCall(url, aifData, true, false);
      
      if (addassetData["error_code"] == "100") {
        navigate(
          process.env.PUBLIC_URL +
            "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=10"
        );

        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `Unlisted / AIF Equity added Successfully!`,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `Unlisted / AIF Equity not added, Something went wrong!`,
            type: "error",
          },
        });
      }
    } catch (err) {
      console.log('here123435--', err);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  const updateAIFequity = async () => {
    // e.preventDefault();

    try {
      let url = ADVISORY_UPDATE_ASSETS_API;
      // let payload = assetsDetails;
      let aifData = assetsDetails;
      aifData["id"] = assetEditId;
      aifData["user_id"] = getParentUserId();
      // let member_id = await getFpUserDetailsId();
      aifData["asset_member_id"] = formData.aifmembername;
      aifData["subcategorydetail"] = "Unlisted / AIF Equity";

      aifData["asset_name"] = formData.nameOfUnlistedEquity;
      aifData["asset_purchase_date"] = formData.purchaseDate ? moment(formData.purchaseDate).format(
        "DD/MM/YYYY") : "";
      aifData["asset_units"] = formData.noofShares;
      aifData["asset_purchase_amount"] = formData.avgbuyPrice;
      aifData["asset_current_unit_price"] = formData.currentPrice;
      aifData["totalinvestedvalue"] = formData.investedValue;
      aifData["totalpurchasevalue"] = formData.currentValue;
      aifData["categorydetail"] = "Equity";
      aifData["asset_sub_category_id"] = 33;

      let addassetData = await apiCall(url, aifData, true, false);
      if (addassetData["error_code"] == "100") {
        navigate(
          process.env.PUBLIC_URL +
            "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=10"
        );
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `Unlisted / AIF Equity updated Successfully!`,
            type: "success",
          },
        });
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: `Unlisted / AIF Equity not updated, Something went wrong!`,
            type: "error",
          },
        });
      }
    } catch (err) {
      // setIsLoading(false);
      console.log('here983435--', err);
      toastr.options.positionClass = "toast-bottom-left";
      toastr.error("Something went wrong");
    }
  };

  useEffect(()=>{
      const inv_val = formData.noofShares*formData.avgbuyPrice
      const cur_val = formData.noofShares*formData.currentPrice
      setFormData({...formData,investedValue:inv_val,currentValue:cur_val})
  },[formData.noofShares,formData.avgbuyPrice,formData.currentPrice])
  simpleValidator.current.purgeFields();

  const showSubmit = () => {
    if (formData.typeOfAsset === "EPF") {
      return showEPFForm;
    } else {
      return true;
    }
  };
  // Select Options Styles
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "#ffff",
        color: isFocused ? "#042b62" : isSelected ? "#042b62" : "gray",
        cursor: "pointer",
      };
    },
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      scrollBehavior: "smooth",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#042b62",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  return (
    <>
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          <div className="d-flex">
            <Link
              to={
                process.env.PUBLIC_URL +
                "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=10"
              }
            >
              {" "}
              <img
                style={{
                  transform: "rotate(180deg)",
                }}
                width={20}
                height={20}
                src={process.env.PUBLIC_URL + "/static/media/icons/chevron.svg"}
              />
            </Link>

            {addForm && (
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Add Your Unlisted/AIF Equity
              </h3>
            )}

            {updateForm && (
              <h3
                className="text-center pb-0 mb-0 ps-2"
                style={{
                  flexGrow: 1,
                }}
              >
                Edit Your Unlisted/AIF Equity
              </h3>
            )}
          </div>
          <hr style={{ color: "#afafaf" }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              {addForm && (
                <p className="text-center">
                  Enter Your Details To Add Existing Assets
                </p>
              )}

              {updateForm && (
                <p className="text-center">
                  Enter Your Details To Edit Existing Assets
                </p>
              )}

              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div className="">
                    <span className="lbl-newbond">Equity Name *</span>
                    <br />
                    <input
                      placeholder="Enter Name Of Unlisted Equity"
                      className={` w-100 fntoo-textbox-react inputPlaceholder`}
                      type="text"
                      name="nameOfUnlistedEquity"
                      value={formData.nameOfUnlistedEquity}
                      onChange={(e) => onInputChange(e)}
                    />
                    {simpleValidator.current.message(
                      "nameOfUnlistedEquity",
                      formData.nameOfUnlistedEquity,
                      "required"
                    )}
                  </div>
                </div>
                <div className="my-md-4">
                  <div className="">
                    <span className="lbl-newbond">
                      Who Is This Investment For*
                    </span>
                    <br />
                    <Select
                      className={`fnto-dropdown-react`}
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={customStyles}
                      options={familyData}
                      name="aifMemberName"
                      onChange={handleAIFEquityMember}
                      value={familyData.filter(
                        (v) => v.value == formData.aifmembername
                      )}
                    />
                    {simpleValidator.current.message(
                      "aifMemberName",
                      formData.aifmembername,
                      "required"
                    )}
                  </div>
                </div>
                <div
                  className="row"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div className="col-md-6 col-12  mt-3 ">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">Purchase Date</span>
                        <br />
                        <div className="bonds-datepicker">
                          <FintooDatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={
                              formData.purchaseDate === ""
                                ? ""
                                : formData.purchaseDate
                              // : formData.purchaseDate
                            }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            name="purchaseDate"
                            customClass="datePickerDMF"
                            maxDate={new Date()}
                            onChange={(e) =>
                              onDateAndSelectInputChange(
                                "purchaseDate",
                                formatDatefun(e)
                              )
                            }
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">No. Of Shares *</span>
                        <br />
                        <input
                          placeholder="Enter No. Of Shares"
                          className={` w-100 fntoo-textbox-react inputPlaceholder`}
                          type="text"
                          value={formData.noofShares}
                          name="noofShares"
                          maxLength={9}
                          onChange={(e) => onInputChange(e, true)}
                        />
                        {simpleValidator.current.message(
                          "no. of Shares",
                          formData.noofShares,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">Avg Buy Price *</span>
                        <br />
                        <input
                          placeholder="Enter Avg Buy Price"
                          className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                          type="text"
                          value={formData.avgbuyPrice}
                          name="avgbuyPrice"
                          maxLength={9}
                          onChange={(e) => onInputChange(e, true)}
                        />
                        {simpleValidator.current.message(
                          "Avg buy Price",
                          formData.avgbuyPrice,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">Invested Value</span>
                        <br />
                        <input
                          placeholder="Enter Invested Value"
                          className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder disabled`}
                          type="text"
                          value={formData.investedValue}
                          maxLength={9}
                          name="investedValue"
                        />
                        {simpleValidator.current.message(
                          "investedValue",
                          formData.investedValue,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">Current Price *</span>
                        <br />
                        <input
                          placeholder="Enter Current Price"
                          className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder`}
                          type="text"
                          value={formData.currentPrice}
                          name="currentPrice"
                          maxLength={9}
                          onChange={(e) => onInputChange(e, true)}
                        />
                        {simpleValidator.current.message(
                          "currentPrice",
                          formData.currentPrice,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mt-3">
                    <div className="my-md-4">
                      <div className="">
                        <span className="lbl-newbond">Current Value</span>
                        <br />
                        <input
                          placeholder="Enter Current Value"
                          className={` w-100 fntoo-textbox-react Rupee-icon inputPlaceholder disabled`}
                          type="text"
                          maxLength={9}
                          value={formData.currentValue}
                          name="currentValue"
                        />
                        {simpleValidator.current.message(
                          "currentValue",
                          formData.currentValue,
                          "required|numeric"
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {showSubmit() && addForm && (
                  <div className="my-md-4">
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => validateForm()}
                    >
                      Save
                    </button>
                  </div>
                )}

                {showSubmit() && updateForm && (
                  <div>
                    <button
                      type="submit"
                      className="d-block m-auto btn btn-primary"
                      onClick={() => updateAIF()}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewUnlisted_Aif_EquityFormView;
