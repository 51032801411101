import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from 'simple-react-validator';
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { createPortal } from 'react-dom';
import style from './goldForm.module.css';
import * as constClass from "../../../../constants";
import moment from "moment";
import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
// import moment from "moment";
import { fetchEncryptData, apiCall, getUserId, getItemLocal, getCurrentUserDetails, getFpUserDetailsId, getParentUserId, getFpUserDetailId } from "../../../../common_utilities";
import { DMF_ADD_GOLD, ADVISORY_ADD_ASSETS_API, ADVISORY_UPDATE_ASSETS_API, GET_MEMBER_LIST } from "../../../../constants";
import commonEncode from "../../../../commonEncode";

const numericRegex = new RegExp(/^\d*\.?\d*$/);

const goldAssetsInputs = {
  default: ["numberOfUnits",
    "purchasePrice",
    "investedAmount",
    "currentPrice",
    "currentValue"],
  'Gold ETF': [
    "numberOfUnits",
    "purchasePrice",
    "investedAmount",
    "currentPrice",
    "currentValue",
  ],
  'Physical Gold': [
    "dateOfPurchase",
    "numberOfGms",
    "currentPrice",
    "currentValue"
  ],
  'Sovereign Gold Bonds': [
    "numberOfGms",
    "purchasePrice",
    "investedAmount",
    "currentPrice",
    "currentValue",
  ],
  Others: [
    "dateOfPurchase",
    "numberOfGms",
    "karat",
    "totalInvestedValue",
    "currentPrice",
    "currentValue",
  ],
}

function isInputInPolicy(inputName, policyType) {

  if (!policyType) {
    policyType = "default";
  }

  return goldAssetsInputs[policyType].includes(inputName);
}

const initialValues = {
  goldType: '',
  goldMemberName:"",
  numberOfUnits: '',
  purchasePrice: '',
  investedAmount: '',
  currentPrice: '',
  currentValue: '',
  dateOfPurchase: '', // You can use an appropriate date format (e.g., 'YYYY-MM-DD')
  numberOfGms: '',
  karat: '',
  totalInvestedValue: '',
};

const options_gold_type = [
  { value: "Gold ETF", label: "Gold ETF" },
  { value: "Physical Gold", label: "Physical Gold" },
  { value: "Sovereign Gold Bonds", label: "Sovereign Gold Bonds" },
  { value: "Others", label: "Others" }
]


const NewGoldFormView = () => {
  const [, forceUpdate] = useState();
  const [investedamount, setInvestedAmount] = useState('')
  const [memberid, setMemberId] = useState("");
  const [currentvalue, setCurrentValue] = useState('')
  const [formData, setFormData] = useState(initialValues);
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const { id } = useParams();
  const [usrparts, setUrlPart] = useState("");
  // const [assign , setAssign] =useState('');
  const [editdata, setEditData] = useState('');
  const dispatch = useDispatch();
  const [familyData, setFamilyData] = useState([]);

  const onInputChange = (e, isNumeric) => {
    const name = e.target.name;
    let value = e.target.value;

    if (isNumeric && !numericRegex.test(value) && value !== '') {
      return;
    }

    setFormData({ ...formData, [name]: value });
  }

  const optionSubCategory = {
    "Gold ETF": 70,
    "Physical Gold": 69,
    "Sovereign Gold Bonds": 72,
    "Others": 73
  }

  const onDateAndSelectInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  }

  const validateForm = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
    const isValid = simpleValidator.current.allValid();
    
    if (isValid == true) {
      addgold();
    }
  }

  const getGoldTypeData = (insuranceTypeData, label) => {
    return insuranceTypeData.find((data) => data.label === label);
  }

  useEffect(()=>{
    getFamilyMembers();
  },[])
  const getFamilyMembers = async () => {
    try {
      let data = {
        user_id: getParentUserId(),
        is_direct: "1",
      };

      let member_data = await apiCall(GET_MEMBER_LIST, data, true, false);

      if (member_data.error_code == "100") {
        var member_array = [];
        var members = member_data["data"];
        members.map((member) => {
          if (member.parent_user_id == 0) {
            member_array.push({ value: member.fp_user_id, label: "Self", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            setFormData({
              ...formData,
              goldMemberName: member.fp_user_id,
            });
          
            member_array.push({ value: 0, label: "Family", retirement_age: member.retirement_age, dob: member.dob, life_expectancy: member.life_expectancy, isdependent: member.isdependent });
            
          } else {
            member_array.push({
              value: member.fp_user_id,
              label: member.NAME + " " + member.last_name,
              retirement_age: member.retirement_age,
              dob: member.dob,
              life_expectancy: member.life_expectancy,
              isdependent: member.isdependent
            });
          }
        });

        setFamilyData(member_array);
      } else {
        setFamilyData([]);
      }
    } catch { }
  };

  useEffect(() => {

    reqdata();
    callapi();
  }, [formData?.purchasePrice, formData?.goldType, formData?.numberOfGms, formData?.currentPrice, formData?.karat,]);

  useEffect(() => {
    if (id != undefined) {
      fetchInsuranceData(atob(id));
    }
  }, []);

  
  const handleGoldMember = (selectedOption) => {
    setFormData({
      ...formData,
      goldMemberName: selectedOption.value,
    });
  };

  const fetchInsuranceData = async (usrparts) => {
    try {

      let payload_data =
      {
        user_id: getParentUserId(),
        inv_type: "gold",
        is_direct: constClass.IS_DIRECT,
      }

      let payload = {
        url: constClass.GET_OTHER_INVESTMENTS,
        data: payload_data,
        method: "post",
      };

      
      let res = await fetchEncryptData(payload);

      if (res.error_code == "100") {
        var data = res.data['gold_data']['gold_details']
        
        if (usrparts) {
          let assetDetails = data.find(item => item.id == usrparts ?? usrparts);

          setEditData(assetDetails)
          
          if (assetDetails.asset_name.includes("Gold ETF")) {
            var condition = true
          } 
          
          setFormData((prevFormData) => ({ ...prevFormData, goldType: getGoldTypeData(options_gold_type,condition == true?  assetDetails.asset_type:assetDetails.asset_name).label, numberOfUnits: assetDetails.asset_units, numberOfGms: assetDetails.asset_units, purchasePrice: assetDetails.purchase_rate, currentValue: assetDetails.cr_val, currentPrice: assetDetails.asset_current_unit_price, dateOfPurchase: assetDetails.asset_purchase_date ?? assetDetails.asset_purchase_date, karat: assetDetails.asset_gold_karat, totalInvestedValue:assetDetails.purchase_rate, goldMemberName:assetDetails.asset_member_id}))
        }
      } else {
        console.error(e);
      }
    } catch (e) {

      console.error(":::>>", e);
    }
  };


  const reqdata = async() => {
    try {

      var date = formData.dateOfPurchase == "" || formData.dateOfPurchase == null ? moment(new Date()).format('DD/MM/YYYY') : moment(formData.dateOfPurchase).format('DD/MM/YYYY')
      var date_input = formData.dateOfPurchase
      var asset_units = ''
      var purchasePrice = ''
      var currentPrice = ''
      var karat = ''


      if (formData.goldType == 'Sovereign Gold Bonds') {
        asset_units = formData.numberOfGms
        purchasePrice = formData.purchasePrice
        currentPrice = formData.currentPrice
        setInvestedAmount(Number(purchasePrice) * Number(asset_units))
        setCurrentValue(Number(currentPrice) * Number(asset_units))
      }
      if (formData.goldType == 'Gold ETF') {
        asset_units = formData.numberOfUnits
        purchasePrice = formData.purchasePrice
        currentPrice = formData.currentPrice
        setInvestedAmount(Number(purchasePrice) * Number(asset_units))
        setCurrentValue(Number(currentPrice) * Number(asset_units))
      }
  
      if (formData.goldType == 'Physical Gold') {
        asset_units = formData.numberOfGms
        currentPrice = formData.currentPrice
        setCurrentValue(Number(currentPrice) * Number(asset_units))
      }
      if (formData.goldType == 'Others') {
        asset_units = formData.numberOfGms
        karat = formData.karat
        purchasePrice = formData.totalInvestedValue
        currentPrice = formData.currentPrice
        // setInvestedAmount(Number(karat) * Number(asset_units))
        setCurrentValue(Number(currentPrice) * Number(asset_units))

      }

      let member_id = formData.goldMemberName;

      let asset_sub_category_id = optionSubCategory[formData.goldType]

      var data_req = {
        "Created_By": 0,
        "Updated_By": 0,
        "asset_amount": "",
        "asset_abreturn": "0",
        "annual_growth_rate": "10",
        "asset_broker_id": 0,
        "asset_category_id": 42,
        "asset_citytype": "0",
        "asset_current_unit_price": currentPrice,
        "asset_currency": false,
        "asset_ecas_type": "manual",
        "asset_epf_ismanual": "1",
        "asset_folio_number": null,
        "asset_footnote": null,
        "asset_frequency": "1",
        "asset_goal_link_id": "",
        "asset_goalname": null,
        "asset_gold_karat": karat,
        "asset_isActive": "1",
        "asset_ismortgage": "0",
        "asset_isperpetual": "3",
        "asset_isallocation": false,
        "asset_iselss": "1",
        "asset_islinkable": true,
        "asset_isrecurring": false,
        "asset_isrented": "1",
        "asset_maturity_amt": 0,
        "asset_maturity_date": null,
        "asset_member_id": member_id,
        "asset_mf_end_date": null,
        "asset_name": formData.goldType,
        "asset_pan": null,
        "asset_payout_type": "1",
        "asset_pin_code": "",
        "asset_purchase_amount": purchasePrice,
        "asset_purchase_date": date,
        "asset_rental_amount": "",
        "asset_rental_income": null,
        "asset_ror": "0",
        "asset_sub_category_id": asset_sub_category_id,
        "asset_unique_code": "",
        "asset_units": asset_units,
        "categorydetail": "",
        "created_datetime": date_input,
        "employee_contribution": "",
        "employer_contribution": "",
        "installment_ispaid": "1",
        "membername1": "",
        "stock_mf": null,
        "stock_name": null,
        "subcategorydetail": formData.goldType,
        "totalinvestedvalue": currentvalue,
        "totalpurchasevalue": investedamount,
        "totalmaturtiyamount": "",
        "updated_datetime": date,
        "user_id": getParentUserId(),
        "scheme_equityshare": {},
        "asset_source": "1"
      }

      if (id != undefined) {
        data_req = {
          "Created_By": "999",
          "Updated_By": 0,
          "asset_amount": 0,
          "asset_annual_growth_rate": "10.00",
          "asset_broker_id": 0,
          "asset_category_id": 42,
          "asset_citytype": "0",
          "asset_currency": false,
          "asset_current_unit_price": currentPrice,
          "asset_ecas_type": "manual",
          "asset_epf_ismanual": "1",
          "asset_folio_number": null,
          "asset_footnote": null,
          "asset_frequency": 1,
          "asset_goal_link_id": 0,
          "asset_goalname": null,
          "asset_gold_karat": karat,
          "asset_isActive": "1",
          "asset_isMortgage": "0",
          "asset_isPerpetual": "3",
          "asset_isallocation": false,
          "asset_iselss": "1",
          "asset_islinkable": true,
          "asset_isrecurring": false,
          "asset_isrented": "1",
          "asset_maturity_amt": 0,
          "asset_maturity_date": null,
          "asset_member_id": formData.goldMemberName,
          "asset_mf_end_date": null,
          "asset_name": formData.goldType,
          "asset_pan": null,
          "asset_payout_type": "1",
          "asset_pin_code": "",
          "asset_purchase_amount": purchasePrice,
          "asset_purchase_date": null,
          "asset_rental_amount": "0.00",
          "asset_rental_income": null,
          "asset_ror": "0.00",
          "asset_source": "1",
          "asset_sub_category_id": 70,
          "asset_unique_code": "",
          "asset_units": asset_units,
          "categorydetail": "Gold",
          "created_datetime": date,
          "employee_monthly_contrib": "0.00",
          "employeer_monthly_contrib": "0.00",
          "id": editdata.id,
          "installment_ispaid": true,
          "linked_goals_id": [],
          "membername1": "test",
          "stock_mf": null,
          "stock_name": null,
          "subcategorydetail": formData.goldType,
          "totalinvestedvalue": currentvalue,
          "totalpurchasevalue": investedamount,
          "updated_datetime": date,
          "user_id": getParentUserId(),
          "asset_ismortgage": "0",
          "asset_isperpetual": "3",
          "annual_growth_rate": "10.00",
          "totalmaturtiyamount": null,
          "employee_contribution": 0,
          "employer_contribution": 0
        }
      }

      return data_req
    }
    catch (e) {
      console.log(">>>>>>", e)
    }
  }
  const callapi = async () => {
    let insurance_for_member = await getFpUserDetailsId()
    setMemberId(insurance_for_member)
    console.log(insurance_for_member, 'insurance_for_member')
  }
  const addgold = async () => {
    try {
      // console.log(process.env.REACT_APP_PYTHON_URL +"restapi/assetsaddApi/" == "https://stg.minty.co.in/restapi/assetsaddApi/","hhh")
      let req = await reqdata();
      console.log(req, "req>>>>")
      if (id != undefined) {
        var config = {
          method: "POST",
          url: ADVISORY_UPDATE_ASSETS_API,
          data: req

        };
      }
      else {
        var config = {
          method: "POST",
          url: ADVISORY_ADD_ASSETS_API,
          data: req

        };
      }
      let response = await fetchEncryptData(config);
      
      if (response['error_code'] == '100') {
        if (id != undefined) {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Gold Updated Successfully!", type: "success" },
          })
        }
        else {
          dispatch({
            type: "RENDER_TOAST",
            payload: { message: "Gold Added Successfully!", type: "success" },
          })

        }
      }
      navigate(process.env.PUBLIC_URL + '/direct-mutual-fund/portfolio/dashboard/?assetTabNumber=7');
    }

    catch (e) {
      console.log(":::::", e)
    }

  }

  const emptystates = () => {
    setFormData((prevformData) => ({ ...prevformData, purchasePrice: "", numberOfGms: "", currentPrice: "", dateOfPurchase: "", numberOfUnits: "" ,totalInvestedValue:''}))
  }

  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "#ffff",
        color: isFocused ? "#042b62" : isSelected ? "#042b62" : "gray",
        cursor: "pointer",
      };
    },
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      scrollBehavior: "smooth",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#042b62",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  simpleValidator.current.purgeFields();

  return (
    <>
      {showInfo && createPortal(
        <div className={`${style.popupBackdrop}`} ></div>,
        document.body
      )}
      <div className="px-0 px-md-4 assetForm">
        <div
          className="p-3"
          style={{ border: "1px solid #d8d8d8", borderRadius: 10 }}
        >
          <div className="d-flex">
            <Link to={
              process.env.PUBLIC_URL +
              "/direct-mutual-fund/portfolio/dashboard?assetTabNumber=7"
            }> <img
                style={{
                  transform: "rotate(180deg)",
                }}
                width={20}
                height={20}
                src={process.env.PUBLIC_URL + "/static/media/icons/chevron.svg"}
              /></Link>
            <h3
              className="text-center pb-0 mb-0 ps-2"
              style={{
                flexGrow: 1,
              }}
            >
              {id ? 'Edit Your Gold Asset' : 'Add Your Gold Assets'}
            </h3>
          </div>
          <hr style={{ color: '#afafaf' }} />
          <div className="row">
            <div className="col-12 col-md-11 col-lg-8 m-auto">
              <p className="text-center">
                {id ? 'Enter Your Details' : 'Enter Your Details To Add Existing Gold Assets'}
              </p>
              <br />
              <br />
              <div>
                <div className="my-md-4">
                  <div >
                    <span className="lbl-newbond">
                      Select Gold Type *
                    </span>
                    <br />
                    <Select
                      className="fnto-dropdown-react"
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      options={options_gold_type}
                      value={getGoldTypeData(options_gold_type, formData.goldType)}
                      name='goldType'
                      onChange={(e) => { onDateAndSelectInputChange('goldType', e.label), emptystates(); }}
                    />
                    {simpleValidator.current.message('goldType', formData.goldType, 'required')}
                  </div>
                </div>
                <div className="my-md-4">
                  <div className="">
                    <span className="lbl-newbond">
                      Who Is This Investment For*
                    </span>
                    <br />
                    <Select
                      className={`fnto-dropdown-react`}
                      classNamePrefix="sortSelect"
                      isSearchable={false}
                      styles={customStyles}
                      options={familyData}
                      name="goldMemberName"
                      onChange={handleGoldMember}
                      value={familyData.filter(
                        (v) => v.value == formData.goldMemberName
                      )}
                    />
                    
                    {simpleValidator.current.message(
                      "goldMemberName",
                      formData.goldMemberName,
                      "required"
                    )}
                  </div>
                </div>
                <div>
                  <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                      isInputInPolicy('numberOfUnits', formData.goldType) && (<div className="col-md-12 col-12">
                        <div className="my-md-4">
                          <div>
                            <span className="lbl-newbond">
                              Number Of Units *
                            </span>
                            <br />
                            <span style={{ position: 'relative' }}>
                              <input
                                placeholder="Enter Number Of Units"
                                className={` w-100 fntoo-textbox-react`}
                                type="text"
                                name="numberOfUnits"
                                value={formData.numberOfUnits}
                                onChange={(e) => onInputChange(e, true)}
                              />
                              <span className={`${style.infoIcon}`} style={{ zIndex: showInfo ? '100' : '0' }} onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)}>
                                <AiOutlineInfoCircle />
                              </span>
                              {simpleValidator.current.message('numberOfUnits', formData.numberOfUnits, 'required')}

                            </span>
                            {
                              showInfo && <div className={`${style.popupElem}`}>
                                <b>Note:</b>
                                <br />
                                We are taking the units to calculate the exact value and return for your investment according to current market price.
                              </div>
                            }
                          </div>
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('dateOfPurchase', formData.goldType) && (<div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Date Of Purchase
                            </span>
                            <br />
                            <div className="bonds-datepicker">
                              <FintooDatePicker
                                dateFormat="yyyy/MM/dd"
                                selected={formData.dateOfPurchase === "" ? "" : new Date(formData.dateOfPurchase)}

                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name='dateOfPurchase'
                                maxDate={new Date()}
                                customClass="datePickerDMF"
                                onChange={(e) => onDateAndSelectInputChange('dateOfPurchase', formatDatefun(e))}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>

                            {/* {simpleValidator.current.message('purchasePrice', formData.dateOfPurchase, 'required')} */}
                          </div>
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('numberOfGms', formData.goldType) && (<div className={`col-md-${formData.goldType === 'Physical Gold' || formData.goldType === 'Others' ? '6' : '12'} col-12`}>
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Number Of Gms *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Number Of Gms"
                              className={` w-100 fntoo-textbox-react Rupee-icon`}
                              type="text"
                              name="numberOfGms"
                              value={formData.numberOfGms}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('numberOfGms', formData.numberOfGms, 'required|numeric')}
                          </div>
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('karat', formData.goldType) && (<div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Karat *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Karat"
                              className={` w-100 fntoo-textbox-react`}
                              type="text"
                              name="karat"
                              value={formData.karat}
                              onChange={(e) => onInputChange(e)}
                            />
                            {simpleValidator.current.message('karat', formData.karat, 'required')}
                          </div>
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('totalInvestedValue', formData.goldType) && (<div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Total Invested Value *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Total Invested Value"
                              className={` w-100 fntoo-textbox-react Rupee-icon`}
                              type="text"
                              name="totalInvestedValue"
                              value={investedamount?investedamount:formData.totalInvestedValue}
                            onChange={(e) => onInputChange(e, true)}
                            />
                            {investedamount?'':simpleValidator.current.message('totalInvestedValue', formData.totalInvestedValue, 'required|numeric')}
                          </div>
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('purchasePrice', formData.goldType) && (<div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Purchase Price (Per Gm) *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Purchase Price"
                              className={` w-100 fntoo-textbox-react Rupee-icon`}
                              type="text"
                              name="purchasePrice"
                              value={formData.purchasePrice}
                              onChange={(e) => onInputChange(e, true)}
                            />
                            {simpleValidator.current.message('purchasePrice', formData.purchasePrice, 'required|numeric')}
                          </div>
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('investedAmount', formData.goldType) && (<div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Invested Amount
                            </span>
                            <br />
                            <input
                              placeholder="0"
                              className={` w-100 fntoo-textbox-react Rupee-icon`}
                              type="text"
                              name="investedAmount"
                              // value={formData.investedAmount}
                              value={investedamount}
                              onChange={(e) => onInputChange(e, true)}
                            />
                          </div>
                          {simpleValidator.current.message('purchaseValue', formData.investedAmount, 'numeric')}
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('currentPrice', formData.goldType) && (<div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Current Price (Per Gm) *
                            </span>
                            <br />
                            <input
                              placeholder="Enter Current Price"
                              className={` w-100 fntoo-textbox-react Rupee-icon`}
                              type="text"
                              name="currentPrice"
                              value={formData.currentPrice}
                              onChange={(e) => onInputChange(e, true)}
                            />
                          </div>
                          {simpleValidator.current.message('currentPrice', formData.currentPrice, 'required|numeric')}
                        </div>
                      </div>)
                    }

                    {
                      isInputInPolicy('currentValue', formData.goldType) && (<div className="col-md-6 col-12">
                        <div className="my-md-4">
                          <div >
                            <span className="lbl-newbond">
                              Current Value
                            </span>
                            <br />
                            <input
                              placeholder="0"
                              className={` w-100 fntoo-textbox-react Rupee-icon`}
                              type="text"
                              name="currentValue"
                              value={currentvalue}
                              onChange={(e) => onInputChange(e, true)}
                            />
                          </div>
                          {simpleValidator.current.message('pincode', formData.currentValue, "numeric")}
                        </div>
                      </div>)
                    }

                  </div>

                </div>

                <br />
                <div className="my-md-4">
                  <button
                    type="submit"
                    className="d-block m-auto btn btn-primary"
                    onClick={() => { validateForm(); }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
export default NewGoldFormView;
